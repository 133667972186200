module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"lensi.xyz","short_name":"lensi.xyz","description":"A collection of random, unique photographs captured from around the world by web developer & software engineer, Ayotunde Ikuesan.","start_url":"/","theme_color":"#ffffff","background_color":"#000000","display":"standalone","icon":"src/images/favicon.png","orientation":"portrait","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a7a42b624e9dabe9056c2a0bfaa7bcef"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
